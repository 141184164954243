import React, { Component } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "./index.css";
import "./contact.css";
import Nav from "../components/nav"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Contact from "../components/contact";
import imgHero from "../images/gfx/hero-bus-open-data-package.jpg"
import imgMobileHero from "../images/gfx/mobile-hero-bus-open-data-package.jpg"
import imgStaffPassenger from "../images/gfx/staff-shuttle-service.jpg"
import imgLiveTracking from "../images/gfx/school-bus-tracking-system.jpg"
import imgLiveScanning from "../images/gfx/school-bus-tracking-app.jpg"
import imgPassengerScan from "../images/gfx/coach-scan-ticket-side.jpg"
import Footer from "../components/footer";

class BlogPage extends Component {

  componentDidMount(){
    AOS.init({
      duration : 2000
    });
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Staff shuttle service" />
        <Nav activeTab="packages" />
        <div>
            <div>

              <div className="hero-standard" style={{ backgroundImage: `url(${imgHero})`, backgroundColor: '#063a70', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'left center' }}>
                <h1>Staff shuttle service</h1>
                <h2>Manage your staff shuttle service with ShuttleID</h2>
                <div className="hero__links">
                  <Link to="/staff-shuttle-service/#contact" className="btn">Request brochure</Link>
                </div>
              </div>

              <div className="mobile-hero-standard" style={{ backgroundImage: `url(${imgMobileHero})`, backgroundColor: '#084e96', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center top' }}>
                <h1>Staff shuttle service</h1>
                <h2>Manage your staff shuttle service with ShuttleID</h2>
                <div className="hero__links">
                  <Link to="/staff-shuttle-service/#contact" className="btn">Request brochure</Link>
                </div>
              </div>

              <div className="contact-page">
                <div className="article" style={{ maxWidth: 1200 }}>
                  <div className='article-inner'>

                    <div className="contact__section">
                      <p style={{ fontSize: 30, lineHeight: '150%' }}>A staff shuttle bus is a fantastic tool to help improve employee retention, widen your recruitment catchment area and reduce your carbon footprint.</p>
                      <p>Trusted local transport operators will be able get your staff shuttle service operational but all too often you'll find that <strong>contracting a bus and driver isn't enough</strong>:</p>
                      <ul>
                        <li>How will you ensure you're not throwing money away on under-utilised vehicles?</li>
                        <li>How can you keep staff informed of delays or cancellations?</li>
                        <li>How can you ensure only valid passengers are boarding?</li>
                        <li>How can you share the bus location with staff?</li>
                        <li>How will staff book journeys?</li>
                        <li>How can staff pay for travel?</li>
                      </ul>

                      <p>ShuttleID is the affordable, digital bus pass and ticketing system that keeps you in control. Our technology is at the heart of over 8 million passenger journeys each year. Here's how our technology can make your staff shuttle a success:</p>
                      {/* <p><strong>Flexible:</strong> Designed to work how you need it to. Whether you require online payment, online booking, real-time data or just a simple way to monitor passenger activity, we have an option that fits your staff shuttle service like a glove.</p> */}

                      <div className="content-two-col">
                        <div>
                          <img src={imgStaffPassenger} alt="Passenger on staff shuttle service" />
                        </div>
                        <div>
                          <h2>Save time and money</h2>
                          <p>Ensure efficiency with a system that will help reduce your overall transport bill and save daily admin on your staff shuttle service.</p>
                          <p>Whether you need online payment, bookings, real-time data or just a simple way to monitor passenger activity, we have an option that fits your staff shuttle service like a glove.</p>
                          <p>No special hardware is required and your staff shuttle service can be up and running in as little as a few days.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgPassengerScan} alt="Passenger scanning ticket" />
                        </div>
                        <div>
                          <h2>Operator-friendly</h2>
                          <p>ShuttleID is a lightweight tool that delivers tons of value. Instead of driving a wedge between you and the operator, ShuttleID strengthens your relationship and gives you the transparency you crave.</p>
                          <p>This model allows you to retain a direct relationship and rate with your transport operator, which is one reason why it's so popular. It's also the most driver-friendly technology available and proven to work with any driver regardless of technical ability.</p>
                          <p>Use your existing local transport operators or one from our approved network for no extra fee.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgLiveTracking} alt="Staff shuttle bus tracking system" />
                        </div>
                        <div>
                          <h2>Keep staff informed</h2>
                          <p>Our driver app automatically publishes the staff shuttle location data using low-cost, consumer grade tablet and smartphone devices.</p>
                          <p>Travellers can see the exact location of their staff bus in real-time, accessed directly from the mobile ticket on their phone.</p>
                          <p>SMS service alerts, available on our <Link to="/simple-package/">Simple</Link> and <Link to="/complete-package/">Complete</Link> packages, help inform staff ahead of time. Live vehicle tracking complements this with real-time updates, helping further reduce phone calls to you or the operator.</p>
                        </div>
                      </div>

                      <div className="content-two-col">
                        <div>
                          <img src={imgLiveScanning} alt="Staff shuttle bus app" />
                        </div>
                        <div>
                          <h2>Operations Portal</h2>
                          <p>Manage and monitor everything from the Ops Portal.</p>
                          <p>Update capacity and change routes as you go based on real-time insights. Issue new passes at the last minute. Keep staff updated with critical messages.</p>
                          <p>All the power to manage your own staff shuttle service at your fingertips.</p>
                          <p>We'll offer you all the help needed to get your system up and running. Technical support is included as standard for both you and your passengers.</p>
                          {/* <p>With the LIVE add-on, scan log data is automatically available in the back office in real-time, so you can keep track of who has boarded and when.</p>
                          <p>The driver app (connected using 4G networks) updates you immediately with any failed and successful ticket scans, enhancing your staff safeguarding capability.</p>
                          <p>When information is time-sensitive there is no substitute for real-time data.</p> */}
                        </div>
                      </div>

                      <div id="contact" style={{ maxWidth: 800, marginLeft: 'auto', marginRight: 'auto'}}>
                        <h2>More information</h2>
                        <p>Looking to start up a staff shuttle service and don't know where to begin? Already operate a staff shuttle but losing money and creating headaches?</p>
                        <p>Contact our friendly sales team below for more information about how we can help and system pricing.</p>

                        <form  action="/staff-shuttle-service-info-requested/" name="staff-shuttle-enquiry" method="POST" className="contact-form" data-netlify="true" data-netlify-honeypot="bot-field">
                          <p style={{ display: 'none' }}>
                            <label>Don't fill this out if you're human: <input name="bot-field" /></label>
                          </p>
                          <div className="contact-form__group">
                            <label>Your name *</label>
                            <input placeholder="Your name *" name="name" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Company *</label>
                            <input placeholder="Company *" name="company" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Email *</label>
                            <input placeholder="Email *" name="email" type="email" required className="contact-form__input" />
                          </div>
                          <div className="contact-form__group">
                            <label>Phone *</label>
                            <input placeholder="Phone *" name="phone" required className="contact-form__input" />
                          </div>
                          <input type="hidden" name="form-name" value="staff-shuttle-enquiry" />
                          <p>
                            <button className="btn" type="submit">Submit enquiry</button>
                          </p>
                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <Contact />
            </div>
        </div>
        <Footer />
      </Layout>
    );
  }
};

export default BlogPage
